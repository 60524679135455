import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import './notfound.css'
import { gsap, Linear } from 'gsap';
import React, { useEffect } from 'react';



function NotFound() {

    useEffect(() => {
        let t1 = gsap.timeline();
        let t2 = gsap.timeline();
        let t3 = gsap.timeline();

        t1.to(".cog1", {
            transformOrigin: "50% 50%",
            rotation: "+=360",
            repeat: -1,
            ease: Linear.easeNone,
            duration: 8
        });

        t2.to(".cog2", {
            transformOrigin: "50% 50%",
            rotation: "-=360",
            repeat: -1,
            ease: Linear.easeNone,
            duration: 8
        });

        t3.fromTo(
            ".wrong-para",
            {
                opacity: 0
            },
            {
                opacity: 1,
                duration: 1,
                stagger: {
                    repeat: -1,
                    yoyo: true
                }
            }
        );
    }, []);

    return (
        <div class="container">
            <h1 class="first-four">4</h1>
            <div class="cog-wheel1">
                <div class="cog1">
                    <div class="top"></div>
                    <div class="down"></div>
                    <div class="left-top"></div>
                    <div class="left-down"></div>
                    <div class="right-top"></div>
                    <div class="right-down"></div>
                    <div class="left"></div>
                    <div class="right"></div>
                </div>
            </div>

            <div class="cog-wheel2">
                <div class="cog2">
                    <div class="top"></div>
                    <div class="down"></div>
                    <div class="left-top"></div>
                    <div class="left-down"></div>
                    <div class="right-top"></div>
                    <div class="right-down"></div>
                    <div class="left"></div>
                    <div class="right"></div>
                </div>
            </div>
            <h1 class="second-four">4</h1>
            <p class="wrong-para">Uh Oh! Page not found!<Link to="/">Home</Link></p>

        </div>
    )
}
export default withTranslation('notFound')(NotFound);


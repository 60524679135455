import React from 'react';
import { Container, Link } from '@mui/material';
import ProyectColumns from '../core/ProyectColumns';
import Ficha from '../../components/Ficha';

export default function Proyectos() {
    return (
        <Container>
            <ProyectColumns>
                <div>
                    <Link href="https://vinos-7f430.web.app/" target="_blank">
                        <Ficha
                            imageUrl='img\Bodega.jpg'
                            description="Proyecto de tienda de vinos con adaptación 
                            para Tablet y móvil desde plataforma 
                            (html, css, react, javascript, mongoDB)."
                        />
                    </Link>
                </div>
                <div>
                    <Link href="https://krebel.es/" target="_blank" >
                        <Ficha
                            imageUrl='img\LogoKrebel.jpeg'
                            description="Proyecto de ecommerce virtual de ropa con 
                            compra a través de pasarela de pago (redsys). 
                            (html, css, react, mysql, boostrap)"
                        />
                    </Link>
                </div>
                <div>
                    <Link href="https://fervic.es/" target="_blank" >
                        <Ficha
                            imageUrl='img\LogoFerVic.jpeg'
                            description="Proyecto portal taller mecánico con 
                            calendario de reservas (html, css, react, mongodb)."
                        />
                    </Link>
                </div>
                <div>
                    <Link href="https://spotifyclon-6265d.web.app/" target="_blank" >
                        <Ficha
                            imageUrl='img\Spotify.png'
                            description="Proyecto de generación de player con gestión de 
                            canciones via api rest en formato Spotify (css, react, javascript)"
                        />
                    </Link>
                </div>
            </ProyectColumns>
        </Container>
    );
}

import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import { Box, Grid, Link, useTheme } from '@mui/material';

export default function Contact() {

  return (
    <>
        <Grid container spacing={2} justifyContent="end" >
          <Grid>
            <List
              sx={{ width: '100%', maxWidth: 360 }}
              component="nav"
            >
            </List>
          </Grid>
          <Grid padding={4} className="contact">
            <List
              sx={{ width: '100%', maxWidth: 360 }}
              component="nav"
            >
              <ListItemButton>
                <ListItemIcon>
                  <EmailIcon color='secondary' />
                </ListItemIcon>
                <Link
                  href="mailto:jmalcarazp@gmail.com"
                  underline="hover"
                  color="white"
                >jmalcarazp@gmail.com</Link>
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <PhoneAndroidIcon color='secondary' />
                </ListItemIcon>
                <Link
                  href="tel:637017987"
                  underline="hover"
                  color="white"
                >637017987</Link>
              </ListItemButton>
              <ListItemButton>
                <ListItemIcon>
                  <LocationOnIcon color='secondary' />
                </ListItemIcon>
                <Link
                  href="https://maps.app.goo.gl/r11ZpSNudqyfWp6e7"
                  target="_blank"
                  underline="hover"
                  color="white"
                >Mataro, 08304, Barcelona</Link>
              </ListItemButton>
            </List>
          </Grid>
      </Grid>
    </>
  )
}
import React from 'react';
import { Container, Grid } from '@mui/material';

function ProyectColumns({ children }) {
    const columnContent = React.Children.toArray(children);

    return (
        <Container className="projects">
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={10} sm={5} md={3} xl={4} >
                    {columnContent[0]}
                </Grid>

                <Grid item xs={10} sm={5} md={3} xl={4} >
                    {columnContent[1]}
                </Grid>

                <Grid item xs={10} sm={6} md={3} xl={4} >
                    {columnContent[2]}
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={10} sm={5} md={3} xl={4} >
                    {columnContent[3]}
                </Grid>

                <Grid item xs={10} sm={5} md={3} xl={4} >
                    {columnContent[4]}
                </Grid>

                <Grid item xs={10} sm={6} md={3} xl={4} >
                    {columnContent[5]}
                </Grid>
            </Grid>
        </Container>

    );
};

export default ProyectColumns;
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';

const Container = styled(Paper)({
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-block',
    borderRadius: '25px',
});

const Image = styled('img')({
    display: 'block',
    width: '100%',
    height: 'auto',
    transition: 'transform 0.3s ease',
});

const TextContainer = styled('div')({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    padding: '8px',
    boxSizing: 'border-box',
    transition: 'transform 0.3s ease',
    transform: 'translateY(100%)',
});

const Text = styled(Typography)({
    color: '#fff',
    margin: 0,
    fontSize: '16px',
});

const Ficha = ({ imageUrl, description }) => {
    const [hovered, setHovered] = useState(false);

    return (
        <Container
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Image
                src={imageUrl}
                style={{ transform: hovered ? 'scale(1.1)' : 'scale(1)' }}
                alt="Image Description"
            />
            <TextContainer
                style={{ transform: hovered ? 'translateY(0)' : 'translateY(100%)' }}
            >
                <Text>{description}</Text>
            </TextContainer>
        </Container>
    );
};

export default Ficha;

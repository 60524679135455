import { Grid } from "@mui/material";

export default function SobreMi() {

    return (
        <Grid container spacing={2} justifyContent="center" >
            <Grid item xs={8} sm={8} md={6} xl={6} className="blogCase">
                <div className="blog">
                    Apasionado desarrollador web con una sólida pasión por transformar ideas 
                    en experiencias digitales cautivadoras. Desde que descubrí mi fascinación 
                    por la programación, he estado inmerso en el mundo del desarrollo web, 
                    explorando nuevas tecnologías y desafiándome constantemente para mejorar 
                    mis habilidades.
                    <br></br>
                    Mi viaje en el desarrollo web comenzó en 2023, cuando me sumergí en el 
                    estudio de lenguajes como HTML, CSS, JavaScript y React. Lo que inicialmente 
                    comenzó como un simple pasatiempo se convirtió rápidamente en una carrera 
                    profesional gratificante.
                    <br></br>
                    Lo que más me apasiona de mi trabajo es la posibilidad de fusionar creatividad 
                    y funcionalidad para crear soluciones digitales únicas y efectivas. Me encanta 
                    colaborar con diseñadores, especialistas en experiencia de usuario y otros 
                    desarrolladores para llevar las ideas desde la fase conceptual hasta la realidad 
                    digital. Cada proyecto es una oportunidad para aprender algo nuevo y superar 
                    desafíos, y estoy constantemente buscando formas de ampliar mis conocimientos y 
                    habilidades.
                    <br></br>
                    Mi enfoque en el desarrollo web va más allá del código. Creo firmemente en la 
                    importancia de comprender las necesidades y expectativas de los usuarios finales, 
                    y me esfuerzo por crear experiencias que no solo sean visualmente atractivas, sino 
                    también intuitivas y accesibles para todos. Me considero un solucionador de 
                    problemas creativo y siempre estoy buscando formas innovadoras de abordar los 
                    desafíos técnicos y de diseño que se presentan en cada proyecto.
                    <br></br>
                    Cuando no estoy frente a la pantalla, me encanta explorar nuevas tecnologías, 
                    asistir a conferencias y meetups de desarrollo web, y compartir conocimientos con 
                    la comunidad. Creo en el poder del aprendizaje continuo y estoy comprometido/a a 
                    mantenerme al día con las últimas tendencias y avances en el campo del desarrollo web.
                    <br></br>
                    Me considero una persona creativa, dinamica, con vision de lograr mas de los
                    siguientes objetivos planteados,
                    habilidades comunicativas, trabajo en equipo, con
                    capacidades de liderazgo, perseverancia, actitud responsable y resolutiva.
                    <br></br>
                    Estoy emocionado/a de seguir creciendo como desarrollador web y de enfrentar nuevos 
                    desafíos emocionantes en el futuro. Siempre estoy abierto/a a nuevas oportunidades 
                    de colaboración y estoy ansioso/a por contribuir a proyectos innovadores que marquen 
                    la diferencia en el mundo digital.
                    <br></br>
                    Actualmente mi objetivo es poder desarrollarme en el sector
                    tecnologico en el area de programacion FullStack Developer.
                    <br></br>

                    ¡Gracias por tomarte el tiempo de conocerme mejor!


                </div>
            </Grid>
        </Grid>



    )
};
import * as React from 'react';
import { styled } from '@mui/system';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import { Box, Container, Grid, Link, List, Typography, useTheme } from '@mui/material';

const Footer = styled('footer')({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '50px', /* Ajusta el ancho según tus necesidades */
  height: '100vh', /* Ajusta la altura según tus necesidades */
  backgroundColor: '#6ACEB9', /* Color de fondo del pie de página */
  color: '#fff', /* Color del texto en el pie de página */
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '10px', /* Ajusta el espaciado interno según tus necesidades */
  writingMode: 'vertical-rl',
  transform: 'rotate(180deg)'
});

const MyFooter = () => {
  const theme = useTheme();

  const marginValues = {
    xs: theme.spacing(2),
    sm: theme.spacing(3),
    md: theme.spacing(4),
    lg: theme.spacing(5),
    xl: theme.spacing(5),
  };
  return (
    <Container sx={{ marginY: marginValues }}>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
        <Typography
        backgroundColor={'#6ACEB9'}
        color={'white'}
        >Created by JoseMalpe ©  2023</Typography>
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        <Footer>Created by JoseMalpe ©  2023</Footer>
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      <Grid container spacing={2} >
        <Grid>
          <List
            sx={{ width: '100%', maxWidth: 360 }}
            component="nav"
          >
          </List>
        </Grid>
        <Grid padding={4} className="contact">
          <List
            sx={{ width: '100%', maxWidth: 360 }}
            component="nav"
          >
            <ListItemButton>
              <ListItemIcon>
                <EmailIcon color='secondary' />
              </ListItemIcon>
              <Link
                href="mailto:jmalcarazp@gmail.com"
                underline="hover"
                color="white"
              >jmalcarazp@gmail.com</Link>
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <PhoneAndroidIcon color='secondary' />
              </ListItemIcon>
              <Link
                href="tel:637017987"
                underline="hover"
                color="white"
              >637017987</Link>
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <LocationOnIcon color='secondary' />
              </ListItemIcon>
              <Link
                href="https://maps.app.goo.gl/r11ZpSNudqyfWp6e7"
                target="_blank"
                underline="hover"
                color="white"
              >Mataro, 08304, Barcelona</Link>
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Box>
    </Container>
  );
};

export default MyFooter;
